import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import * as Icon from "react-feather";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";

import { history } from "../../../history";

import Cookies from "js-cookie";
import axios from "axios";
import Domain from "../../../configs/apiConfig";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const handleLogout = (e) => {
  e.preventDefault();
  Cookies.remove("token");
  Cookies.remove("identifier");
  history.push("/");
};

const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/account/account-settings")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" href="#" onClick={(e) => handleLogout(e)}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    name: "",
    avatar: "",
  };
  validateSession = async (token) => {
    await axios({
      method: "post",
      url: `${Domain}/api/v1/admin/role`,
      headers: {
        token,
      },
    })
      .then((response) => {
        if (response.data && response.data.userName && response.data.role) {
          console.log("Valid session");
        } else {
          Cookies.remove("token");
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
        Cookies.remove("token");
        history.push("/");
      });
  };

  fetchUserName = async () => {
    await axios({
      method: "post",
      url: `${Domain}/api/v1/admin/role`,
      headers: {
        token: Cookies.get("token"),
      },
    })
      .then((response) => {
        if (response.data) {
          Cookies.set(
            "identifier",
            {
              n: response.data.name,
            },
            { secure: true, expires: 1 }
          );
          this.setState({ name: response.data.name });
          const avatar = response.data.name.charAt(0).toUpperCase();
          this.setState({ avatar });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async UpdateSetUserInfo() {
    Cookies.remove("identifier");
    await this.fetchUserName();
  }

  async componentDidMount() {
    if (Cookies.get("identifier")) {
      const details = Cookies.getJSON("identifier");
      if (details.n) {
        this.setState({ name: details["n"] });
        this.setState({ avatar: details["n"].charAt(0).toUpperCase() });
      } else {
        this.UpdateSetUserInfo();
      }
    } else {
      this.UpdateSetUserInfo();
    }

    if (Cookies.get("token")) {
      await this.validateSession(Cookies.get("token"));
    } else {
      history.push("/");
    }
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">{this.state.name}</span>
              <span className="user-status">My Profile</span>
            </div>
            <span data-tour="user">
              <Avatar
                color="primary"
                className="mr-1"
                content={this.state.avatar}
                status="online"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
